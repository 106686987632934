import { extendTheme, ThemeConfig } from '@chakra-ui/react'

export const colorConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  colors: {
    brand: {
      100: '#0047ab',
      200: '#052F5E',
      300: '#4D4D4D',
      400: '#0D243C',
    },
    cbtn: {
      primary: '#14c741',
      hover: '#00da3e',
    },
  },
  fonts: {
    heading: 'Inria Serif',
    body: 'Open Sans',
  },
  fontWeights: {
    extrabold: '800',
  },
})
