// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN
const VERCEL_ENV = process.env.VERCEL_ENV ?? process.env.NEXT_PUBLIC_VERCEL_ENV

Sentry.init({
  dsn:
    SENTRY_DSN ??
    'https://cdcf0fa461b9457eb60e6f3419dbdbb4@o1008975.ingest.sentry.io/5987189',
  environment: VERCEL_ENV ?? 'development',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
