import { ChakraProvider } from '@chakra-ui/react'
import App from 'next/app'
import { theme } from '../components/style/theme'
import { handlePageViewed } from '../utils/handle-page-viewed'

class MyApp extends App {
  componentDidMount = () => {
    try {
      this.registerPageViewOnRouteChange()
    } catch {}
  }

  registerPageViewOnRouteChange() {
    handlePageViewed(window.location.pathname, {
      url: window.location.href,
      pixel_ratio: window.devicePixelRatio,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      tz_offset: new Date().getTimezoneOffset(),
    })

    this.props.router.events.on('routeChangeStart', (path, { shallow }) => {
      if (!shallow) {
        handlePageViewed(path, {
          url: `${String(window.origin)}${String(path)}`,
          pixel_ratio: window.devicePixelRatio,
          screen_height: window.screen.height,
          screen_width: window.screen.width,
          tz_offset: new Date().getTimezoneOffset(),
        })
      }
    })
  }

  render() {
    return (
      <ChakraProvider theme={theme}>
        <this.props.Component {...this.props.pageProps} />
      </ChakraProvider>
    )
  }
}

export default MyApp
