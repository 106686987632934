import { handleError } from './handle-error'

const URL = '/api/analytics/page-viewed'

type HandlePageViewedData = {
  url: string
  pixel_ratio: number
  screen_height: number
  screen_width: number
  tz_offset: number
}

type PrepareBody = {
  path: string
  url: string
  pixel_ratio: number
  screen_height: number
  screen_width: number
  tz_offset: number
}

export function handlePageViewed(path: string, data: HandlePageViewedData) {
  fetch(URL, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(prepareBody(path, data)),
  }).catch(handleError)
}

function prepareBody(path: string, data: HandlePageViewedData): PrepareBody {
  return {
    path,
    url: data.url,
    pixel_ratio: data.pixel_ratio,
    screen_height: data.screen_height,
    screen_width: data.screen_width,
    tz_offset: data.tz_offset,
  }
}
